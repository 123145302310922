import { createSlice } from '@reduxjs/toolkit';

import { getFrameworkById, searchFrameworks} from "../../helpers/backend_helper";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  frameworks: [],
  framework: null,
  sortBy: null,
  filters: {
    name: [],
  },
};

const slice = createSlice({
  name: 'framework',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FRAMEWORKS
    getFrameworksSuccess(state, action) {
      state.isLoading = false;
      state.frameworks = action.payload;
    },

    // GET - FRAMEWORK
    getFrameworkSuccess(state, action) {
      state.isLoading = false;
      state.framework = action.payload;
    },

    //  SORT & FILTER FRAMEWORKS
    sortByFrameworks(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    sortByFrameworks,
} = slice.actions;

// ----------------------------------------------------------------------

export function getFrameworks(framework) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products');
      const response = await searchFrameworks(framework);
      dispatch(slice.actions.getFrameworksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFramework(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products/product', {
      //   params: { name },
      // });

      const response = await getFrameworkById(id);
      dispatch(slice.actions.getFrameworkSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
