import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// components
import LoadingScreen from '../components/LoadingScreen';
// config
import { PATH_AFTER_LOGIN } from '../config';
// guards
import GuestGuard from '../guards/GuestGuard';
import RoledGuard from '../guards/RoleGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// layouts
import MainLayout from '../layouts/main';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function  Router() {
  const allRoutes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
              <GuestGuard>
                <Register />
              </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
          <RoledGuard>
            {/* <AuthGuard> */}
            <DashboardLayout />
            {/* </AuthGuard> */}
          </RoledGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'videoScript',
          children: [
            { element: <Navigate to="/dashboard/videoScript/list" replace />, index: true },
            { path: 'list', element: <VideoScript /> },
            { path: 'new', element: <VideoScriptCreate /> },
            { path: 'edit/:id', element: <VideoScriptCreate /> },
            { path: 'detail/:id', element: <VideoScriptDetail /> },
            { path: 'technical/:id', element: <VideoScriptTechnical /> },
          ],
        },
        {
          path: 'framework',
          children: [
            { element: <Navigate to="/dashboard/framework/list" replace />, index: true },
            { path: 'list', element: <FrameworkList /> },
            { path: 'new', element: <FrameworkCreate /> },
            { path: 'edit/:id', element: <FrameworkCreate /> },
            { path: 'detail/:id', element: <FrameworkDetail /> },
          ],
        },
        {
          path: 'ia',
          children: [
            { element: <Navigate to="/dashboard/ia/list" replace />, index: true },
            { path: 'list', element: <IaList /> },
            { path: 'new', element: <IaCreate /> },
            { path: 'edit/:id', element: <IaCreate /> },
          ],
        },
        {
          path: 'character',
          children: [
            { element: <Navigate to="/dashboard/character/list" replace />, index: true },
            { path: 'list', element: <CharacterCards /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: 'edit/:id', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'technicalScriptView/:id', element: <TechnicalScriptView /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        // { element: <HomePage />, index: true }, //todo uncomment when ready
        { element: <Navigate to='/auth/login' replace />, index: true },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]

  // genarate all router paths
  const resources=[];
  const push =(pathStr)=>{
    const path = `/${pathStr.replace(/^\/+/g, '')}` 
    resources.push({
      name: path,
      alias: path,// `${path.split("/")?.[1] ?? ""}-${path.split("/")?.[2] ?? ""}`,
      type: "ui",
    });
  }
  // eslint-disable-next-line array-callback-return
  allRoutes.map(routeA=>{
    // eslint-disable-next-line no-unused-expressions,array-callback-return
    routeA?.children && routeA.children.map(routeB=>{
      // eslint-disable-next-line no-unused-expressions,array-callback-return
      !routeB.children ? routeB?.path && push((`${routeA?.path }/${ routeB?.path}`).replace("*","")) : routeB.children.map(routeC=>{
        const path = `${routeA?.path }/${ routeB?.path}/${routeC.path}`;
        // eslint-disable-next-line no-unused-expressions
        routeC.path && push(path);
      })
    })
  })
// post paths to resources api
  try{
  // addManyResources(resources).then((result) => result);
  }catch(error){
    console.error("addManyResources error---",error);
  }

console.log("resources---", resources)
  return useRoutes(allRoutes);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const CharacterCards = Loadable(lazy(() => import('../pages/dashboard/CharacterCards')));
const VideoScript = Loadable(lazy(() => import('../pages/dashboard/VideoScript')));
const VideoScriptCreate = Loadable(lazy(() => import('../pages/dashboard/VideoScriptCreate')));
const VideoScriptDetail = Loadable(lazy(() => import('../pages/dashboard/VideoScriptDetail')));
const VideoScriptTechnical = Loadable(lazy(() => import('../pages/dashboard/VideoScriptTechnical')));
const FrameworkList = Loadable(lazy(() => import('../pages/dashboard/FrameworkList')));
const FrameworkCreate = Loadable(lazy(() => import('../pages/dashboard/FrameworkCreate')));
const FrameworkDetail = Loadable(lazy(() => import('../pages/dashboard/FrameworkDetail')));
const IaList = Loadable(lazy(() => import('../pages/dashboard/IaList')));
const IaCreate = Loadable(lazy(() => import('../pages/dashboard/IaCreate')));
// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const TechnicalScriptView = Loadable(lazy(() => import('../pages/technicalScriptView')));
