import { createSlice } from '@reduxjs/toolkit';

import { getScriptById, searchScripts} from "../../helpers/backend_helper";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  scripts: [],
  script: null,
  sortBy: null,
  filters: {
    name: [],
  },
};

const slice = createSlice({
  name: 'script',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Scripts
    getScriptsSuccess(state, action) {
      state.isLoading = false;
      state.scripts = action.payload;
    },

    // GET - Script
    getScriptSuccess(state, action) {
      state.isLoading = false;
      state.script = action.payload;
    },

    //  SORT & FILTER Scripts
    sortByScripts(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    sortByScripts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getScripts(script) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products');
      const response = await searchScripts(script);
      dispatch(slice.actions.getScriptsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScript(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products/product', {
      //   params: { name },
      // });

      const response = await getScriptById(id);
      dispatch(slice.actions.getScriptSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
