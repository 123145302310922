// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  monitor: getIcon('ic_kanban'),
};

const navConfig = [
  // // GENERAL
  // // ----------------------------------------------------------------------
   {
     subheader: 'general',
     items: [
       { title: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
       { title: 'Video Scripts', path: PATH_DASHBOARD.videoScript.list, icon: ICONS.chat },
     ],
   },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : Entities
      {
        title: 'Entities',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.monitor,
        children: [
          { title: 'Frameworks', path: PATH_DASHBOARD.framework.list },
          { title: 'personajes', path: PATH_DASHBOARD.character.list },
          { title: 'IAs', path: PATH_DASHBOARD.ia.list },
        ],
      },
    ],
  },
];

export default navConfig;
