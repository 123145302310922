// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components',
  technicalScriptView: '/technicalScriptView'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  videoScript: {
    root: path(ROOTS_DASHBOARD, '/videoScript'),
    list: path(ROOTS_DASHBOARD, '/videoScript/list'),
    new: path(ROOTS_DASHBOARD, '/videoScript/new'),
    videoScriptById: path(ROOTS_DASHBOARD, '/videoScript/:id'),
    editById: path(ROOTS_DASHBOARD, '/videoScript/edit/'),
    technicalView: path(ROOTS_DASHBOARD, '/videoScript/technical/'),
  },
  framework: {
    root: path(ROOTS_DASHBOARD, '/framework'),
    list: path(ROOTS_DASHBOARD, '/framework/list'),
    new: path(ROOTS_DASHBOARD, '/framework/new'),
    frameworkById: path(ROOTS_DASHBOARD, '/framework/:id'),
    editById: path(ROOTS_DASHBOARD, '/framework/edit/'),
  },
  ia: {
    root: path(ROOTS_DASHBOARD, '/ia'),
    list: path(ROOTS_DASHBOARD, '/ia/list'),
    new: path(ROOTS_DASHBOARD, '/ia/new'),
    frameworkById: path(ROOTS_DASHBOARD, '/ia/:id'),
    editById: path(ROOTS_DASHBOARD, '/ia/edit/'),
  },
  character: {
    root: path(ROOTS_DASHBOARD, '/character'),
    list: path(ROOTS_DASHBOARD, '/character/list'),
    new: path(ROOTS_DASHBOARD, '/character/new'),
  },
  script: {
    root: path(ROOTS_DASHBOARD, '/script'),
    list: path(ROOTS_DASHBOARD, '/script/list'),
    new: path(ROOTS_DASHBOARD, '/script/new'),
  },
  prompt: {
    root: path(ROOTS_DASHBOARD, '/prompts'),
    list: path(ROOTS_DASHBOARD, '/prompts/list'),
    new: path(ROOTS_DASHBOARD, '/prompts/new'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
