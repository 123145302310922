import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import characterReducer from './slices/character';
import videoScriptReducer from './slices/videoScript';
import frameworkReducer from './slices/framework';
import frameworkTypeReducer from './slices/frameworkType';
import iaReducer from './slices/ias';
import scriptReducer from './slices/script';
import promptReducer from './slices/prompt';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const characterPersistConfig = {
  key: 'character',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const videoScriptPersistConfig = {
  key: 'videoScript',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const frameworkPersistConfig = {
  key: 'framework',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const frameworkTypePersistConfig = {
  key: 'frameworkType',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const iaPersistConfig = {
  key: 'ia',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy'],
};

const rootReducer = combineReducers({
  character: persistReducer(characterPersistConfig, characterReducer),
  videoScript: persistReducer(videoScriptPersistConfig, videoScriptReducer),
  framework: persistReducer(frameworkPersistConfig, frameworkReducer),
  frameworkType: persistReducer(frameworkTypePersistConfig, frameworkTypeReducer),
  ia: persistReducer(iaPersistConfig, iaReducer),
  script: scriptReducer,
  prompt: promptReducer
});

export { rootPersistConfig, rootReducer };
