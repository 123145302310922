import { createSlice } from '@reduxjs/toolkit';

import { getIaById, searchIas} from "../../helpers/backend_helper";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  ias: [],
  ia: null,
  sortBy: null,
  filters: {
    name: [],
  },
};

const slice = createSlice({
  name: 'ia',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET IAS
    getIasSuccess(state, action) {
      state.isLoading = false;
      state.ias = action.payload;
    },

    // GET - IA
    getIaSuccess(state, action) {
      state.isLoading = false;
      state.ia = action.payload;
    },

    //  SORT & FILTER IAS
    sortByIas(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    sortByIas,
} = slice.actions;

// ----------------------------------------------------------------------

export function getIas(ia) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products');
      const response = await searchIas(ia);
      dispatch(slice.actions.getIasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIa(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products/product', {
      //   params: { name },
      // });

      const response = await getIaById(id);
      dispatch(slice.actions.getIaSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
