import { createSlice } from '@reduxjs/toolkit';

import { getCharacterById, searchCharacters} from "../../helpers/backend_helper";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  characters: [],
  character: null,
  sortBy: null,
  filters: {
    name: [],
  },
};

const slice = createSlice({
  name: 'character',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CHARACTERS
    getCharactersSuccess(state, action) {
      state.isLoading = false;
      state.characters = action.payload;
    },

    // GET - CHARACTER
    getCharacterSuccess(state, action) {
      state.isLoading = false;
      state.character = action.payload;
    },

    //  SORT & FILTER CHARACTERS
    sortByCharacters(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    sortByCharacters,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCharacters(character) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products');
      const response = await searchCharacters(character);
      dispatch(slice.actions.getCharactersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCharacter(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products/product', {
      //   params: { name },
      // });

      const response = await getCharacterById(id);
      dispatch(slice.actions.getCharacterSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
