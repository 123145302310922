import { createSlice } from '@reduxjs/toolkit';

import { getPromptById, searchPrompts} from "../../helpers/backend_helper";
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  prompts: [],
  prompt: null,
  sortBy: null,
  filters: {
    name: [],
  },
};

const slice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Prompts
    getPromptsSuccess(state, action) {
      state.isLoading = false;
      state.prompts = action.payload;
    },

    // GET - Prompt
    getPromptSuccess(state, action) {
      state.isLoading = false;
      state.prompt = action.payload;
    },

    //  SORT & FILTER Prompts
    sortByPrompts(state, action) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    sortByPrompts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPrompts(prompt) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products');
      const response = await searchPrompts(prompt);
      dispatch(slice.actions.getPromptsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPrompt(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/products/product', {
      //   params: { name },
      // });

      const response = await getPromptById(id);
      dispatch(slice.actions.getPromptSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
