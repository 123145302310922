import isAfter from "date-fns/fp/isAfter";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

//  .............................. AUTH ....................................
export const userLogin = user => post(url.USER_LOGIN, user, { params: { ...user } });
export const userSignup = user => post(url.USER_SIGNUP, user);   // todo remove later by /register

//  .............................. USERS ....................................
export const addUser = user => post(url.ADD_USER, user);
export const searchUsers = user => post(url.SEARCH_USERS, user);
export const getUser = user => get(url.GET_USER, { params: { ...user } });
export const getUserAccount = user => get(url.ACCOUNT_USER, { params: { ...user } });
export const updateUser = user =>  put(url.UPDATE_USER, user);
export const deleteUser = user => del(url.DELETE_USER, { params: { ...user } });

export const updateFollowUser = user =>  put(url.UPDATE_FOLLOW_USER, user);

//  .............................. CHARACTERS ....................................
export const searchCharacters = character => post(url.SEARCH_CHARACTERS, character);
export const getCharacterById = id => get(url.GET_CHARACTER, { params: { id } });
export const addCharacter = character => post(url.ADD_CHARACTER, character);
export const updateCharacter = character =>  put(url.UPDATE_CHARACTER, character);

//  .............................. FRAMEWORKS ....................................
export const searchFrameworks = framework => post(url.SEARCH_FRAMEWORKS, framework);
export const getFrameworkById = id => get(url.GET_FRAMEWORK, { params: { id } });
export const addFramework = framework => post(url.ADD_FRAMEWORK, framework);
export const updateFramework = framework =>  put(url.UPDATE_FRAMEWORK, framework);

//  .............................. FRAMEWORK TYPES ....................................
export const searchFrameworkTypes = framework => post(url.SEARCH_FRAMEWORKTYPES, framework);
export const getFrameworkTypeById = id => get(url.GET_FRAMEWORKTYPE, { params: { id } });
export const addFrameworkType = framework => post(url.ADD_FRAMEWORKTYPE, framework);
export const updateFrameworkType = framework =>  put(url.UPDATE_FRAMEWORKTYPE, framework);

//  .............................. IAS ....................................
export const searchIas = ia => post(url.SEARCH_IAS, ia);
export const getIaById = id => get(url.GET_IA, { params: { id } });
export const addIa = ia => post(url.ADD_IA, ia);
export const updateIa = ia =>  put(url.UPDATE_IA, ia);

//  .............................. VIDEOSCRIPT ....................................
export const searchVideoScript = videoScript => post(url.SEARCH_VIDEOSCRIPT, videoScript);
export const getVideoScriptById = id => get(url.GET_VIDEOSCRIPT, { params: { id } });
export const addVideoScript = videoScript => post(url.ADD_VIDEOSCRIPT, videoScript);
export const updateVideoScript = videoScript =>  put(url.UPDATE_VIDEOSCRIPT, videoScript);
export const createdScripts = q =>  get(url.CREATED_SCRIPTS, { params: { q } });
export const createdScriptsByCopilots = () =>  get(url.CREATED_SCRIPTS_BY_COPILOTS, { params: { } });
export const countScriptsByIA = () =>  get(url.COUNT_SCRIPTS_BY_IA, { params: { } });
export const countScriptsByPills = () =>  get(url.COUNT_SCRIPTS_BY_PILLS, { params: { } });

//  .............................. TECHNICALSCRIPT ....................................
export const searchTechnicalScript = technicalScript => post(url.SEARCH_TECHNICALSCRIPT, technicalScript);
export const getTechnicalScriptById = id => get(url.GET_TECHNICALSCRIPT, { params: { id } });
export const addTechnicalScript = technicalScript => post(url.ADD_TECHNICALSCRIPT, technicalScript);
export const updateTechnicalScript = technicalScript =>  put(url.UPDATE_TECHNICALSCRIPT, technicalScript);

//  .............................. SCRIPTS ....................................
export const searchScripts = script => post(url.SEARCH_SCRIPTS, script);
export const getScriptById = id => get(url.GET_SCRIPT, { params: { id } });
export const addScript = script => post(url.ADD_SCRIPT, script);
export const updateScript = script =>  put(url.UPDATE_SCRIPT, script);

//  .............................. PROMPTS ....................................
export const searchPrompts = prompt => post(url.SEARCH_PROMPTS, prompt);
export const getPromptById = id => get(url.GET_PROMPT, { params: { id } });
export const addPrompt = prompt => post(url.ADD_PROMPT, prompt);
export const updatePrompt = prompt =>  put(url.UPDATE_PROMPT, prompt);

//  .............................. PAYMENTS ....................................
export const initPayment = payment => post(url.INIT_PAYMENT, payment);
export const validatePayment = () => get(url.VALIDATE_PAYMENT, { params: {  } });

//  .............................. PRODUCTS ....................................
export const searchProducts = product => post(url.SEARCH_PRODUCTS, product);
export const getProductById = id => get(url.GET_PRODUCT, { params: { id } });
export const addProduct = product => post(url.ADD_PRODUCT, product);
export const updateProduct = product =>  put(url.UPDATE_PRODUCT, product);

//  .............................. SALES_ORDERS ....................................
export const searchSalesOrders = item => post(url.SEARCH_SALES_ORDERS, item);
export const getSalesOrderById = id => get(url.GET_SALES_ORDER, { params: { id } });
export const addSalesOrder = item => post(url.ADD_SALES_ORDER, item);
export const updateSalesOrder = item =>  put(url.UPDATE_SALES_ORDER, item);

//  .............................. BLOGS ....................................
export const searchBlogs = blog => post(url.SEARCH_BLOGS, blog);
export const getBlogById = id => get(url.GET_BLOG, { params: { id } });
export const addBlog = blog => post(url.ADD_BLOG, blog);
export const updateBlog = blog =>  put(url.UPDATE_BLOG, blog);
export const updateLikeBlog = blog =>  put(url.UPDATE_LIKE_BLOG, blog);
export const deleteBlog = blog => del(url.DELETE_BLOG, { params: { ...blog } });

//  .............................. RESOURCES ....................................
// add user
export const addManyResources = resources => post(url.ADD_MANY_RESOURCES, resources)
