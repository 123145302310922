// AUTH
export const USER_LOGIN = "/auth/login";
export const USER_REGISTER = "/auth/register";
export const USER_FORGOT_PASSWORD = "/auth/forgotPassword";
export const USERNAME_CHECK = "/auth/check-username";
export const USER_SIGNUP = "/auth/signup";


// USER
export const ACCOUNT_USER = "/users/account";
export const ADD_USER = "/users/create";
// export const GET_USERS = "/users";
export const SEARCH_USERS = "/users/search";
export const GET_USER = "/users/detail";
export const UPDATE_USER = "/users/update";
export const UPDATE_FOLLOW_USER = "/users/updateFollow";
export const DELETE_USER = "/users/delete";
export const CHECK_USER = "/users/check";
export const COUNT_USERS = "/users/count";

// CHARACTERS
export const GET_CHARACTER = "/characters/detail";
export const SEARCH_CHARACTERS = "/characters/search";
export const ADD_CHARACTER = "/characters/create";
export const UPDATE_CHARACTER = "/characters/update";

// FRAMEWOKS
export const GET_FRAMEWORK = "/frameworks/detail";
export const SEARCH_FRAMEWORKS = "/frameworks/search";
export const ADD_FRAMEWORK = "/frameworks/create";
export const UPDATE_FRAMEWORK = "/frameworks/update";

// FRAMEWOK TYPES
export const GET_FRAMEWORKTYPE = "/frameworktypes/detail";
export const SEARCH_FRAMEWORKTYPES = "/frameworktypes/search";
export const ADD_FRAMEWORKTYPE = "/frameworktypes/create";
export const UPDATE_FRAMEWORKTYPE = "/frameworktypes/update";

// FRAMEWOK TYPES
export const GET_IA = "/ias/detail";
export const SEARCH_IAS = "/ias/search";
export const ADD_IA = "/ias/create";
export const UPDATE_IA = "/ias/update";

// VIDEOSCRIPT
export const GET_VIDEOSCRIPT = "/video-scripts/detail";
export const SEARCH_VIDEOSCRIPT = "/video-scripts/search";
export const ADD_VIDEOSCRIPT = "/video-scripts/create";
export const UPDATE_VIDEOSCRIPT = "/video-scripts/update";
export const CREATED_SCRIPTS = "/video-scripts/createdScripts";
export const CREATED_SCRIPTS_BY_COPILOTS = "/video-scripts/createdScriptsByCopilots";
export const COUNT_SCRIPTS_BY_IA =  "/video-scripts/countScriptsByIA";
export const COUNT_SCRIPTS_BY_PILLS =  "/video-scripts/lastScriptsByPill";


// TECHNICALSCRIPT
export const GET_TECHNICALSCRIPT = "/technicalscripts/detail";
export const SEARCH_TECHNICALSCRIPT = "/technicalscripts/search";
export const ADD_TECHNICALSCRIPT = "/technicalscripts/create";
export const UPDATE_TECHNICALSCRIPT = "/technicalscripts/update";

// SCRIPTS
export const GET_SCRIPT = "/scripts/detail";
export const SEARCH_SCRIPTS = "/scripts/search";
export const ADD_SCRIPT = "/scripts/create";
export const UPDATE_SCRIPT = "/scripts/update";

// PROMPTS
export const GET_PROMPT = "/prompts/detail";
export const SEARCH_PROMPTS = "/prompts/search";
export const ADD_PROMPT = "/prompts/create";
export const UPDATE_PROMPT = "/prompts/update";

// PAYMENTS
export const INIT_PAYMENT = "/payments/init";
export const VALIDATE_PAYMENT = "/payments/validate";

// PRODUCTS
export const GET_PRODUCT = "/products/detail";
export const SEARCH_PRODUCTS = "/products/search";
export const ADD_PRODUCT = "/products/create";
export const UPDATE_PRODUCT = "/products/update";

// SALES_ORDERS
export const GET_SALES_ORDER = "/sales-orders/detail";
export const SEARCH_SALES_ORDERS = "/sales-orders/search";
export const ADD_SALES_ORDER = "/sales-orders/create";
export const UPDATE_SALES_ORDER = "/sales-orders/update";

// BLOGS
export const GET_BLOG = "/blogs/detail";
export const SEARCH_BLOGS = "/blogs/search";
export const ADD_BLOG = "/blogs/create";
export const UPDATE_BLOG = "/blogs/update";
export const UPDATE_LIKE_BLOG = "/blogs/updateLike";
export const DELETE_BLOG = "/blogs/delete";

// RESOURCES
export const ADD_MANY_RESOURCES = "/resources/createMany";
